import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ArticleIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const underconstruction = true

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All Articles" />
      {!underconstruction &&
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <article
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small>{post.frontmatter.date}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
      }
      {underconstruction &&
        <article>
          <p>To our comrades,</p>
          <p>
            The Australian Journal of Anarchist Theory is a new publication, founded at the initiative of
            <a href="https://acmeanjin.org"> Anarchist Communists Meanjin</a>,
            <a href="https://blackflagsydney.org"> Black Flag Sydney</a>,
            and <a href="https://geelonganarchists.org/">Geelong Anarchist Communists</a>.
          </p>

          <p>
            Our aim is to provide thorough analyses of theory, history and strategy, from the perspective of
            anarchist-communism. We note that this is a gap in the anarchist scene, both locally and
            internationally; there are comparatively few journals on anarchist theory regularly published
            in English. There are an enormous amount of strategic questions that need to be answered, stories
            from history that need to be told, movements to be analysed, debates to be had, dots
            that need to be joined. We hope to contribute productively to the anarchist movement and
            the working-class’ general movement towards liberation.
          </p>

          <p>
            While we prioritise contributions from our tendency and members of the aforementioned organisations,
            we are open to submissions from sympathisers and fellow-travellers and have no intention to
            be dogmatic about what we believe. <Link to="/contact">If you are interested in writing for the journal, please
              contact us.</Link>
          </p>

          <p>
            The first edition will be released in late 2022. Its theme will be <b>anarchism and organisation</b>,
            so we encourage contributions to be on this topic. Keep an eye on this space for digital versions
            of the journal, as well as a way to purchase physical copies.
          </p>
          <p>
            Sincerely<br />
            The editorial collective
          </p>
          <p>
            The deadline for article submissions will be September 19th. Please try and have proposals submitted
            well in advance so the editors can work with you on your articles.
          </p>
        </article>
      }
    </Layout >
  )
}

export default ArticleIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
